<template lang="pug">
.user-menu__navigation(
  :class="{ 'is-show': showUserMenu }"
)
  .user-menu__overlay(
    @click="$emit('close-menu')"
  )
  .user-menu__content
    .user-menu__header(:class="{'is-dark': isDarkMode}")
      .user-profile__wrapper
        UserMenuProfilePicture
        UserMenuNameLevel
      UserMenuTheme
    .user-menu__main
      .nav-list(
        v-for="(nav, index) in navigation"
        :class="{'is-dark': isDarkMode}"
      )
        BaseButton.user-menu__button(
          v-if="!nav.isFunction"
          :key="index"
          :class="{'is-dark': isDarkMode}"
          :href="getHref(nav)"
          :is-text="true"
          :plain-hover="true"
          :new-tab="nav.newTab"
        ) {{ nav.name }}

        BaseButton.user-menu__button(
          v-else
          :key="index"
          :class="{'is-dark': isDarkMode}"
          :is-text="true"
          :plain-hover="true"
          @click="nav.method"
        ) {{ nav.name }}

      .nav-list.download-app(
        v-if="showDownloadApps"
        :class="{'is-dark': isDarkMode}"
      )
        BaseButton.user-menu__button(
          :class="{'is-dark': isDarkMode}"
          :is-text="true"
          :plain-hover="true"
          :new-tab="true"
          :href="downloadAppLink"
        )
          span.icons
            img.windows-icon(
              :src="appIcon('windows-icon.png')"
            )
            img.mac-icon(
              :src="appIcon('mac-icon.png')"
            )
          span.beta Beta
          span Download App
          span.only-available-for-pc-and-mac Only available for PC / Mac
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import UserMenuProfilePicture from './UserMenuProfilePicture.vue';
import UserMenuNameLevel from './UserMenuNameLevel.vue';
import UserMenuTheme from './UserMenuTheme.vue';

/* eslint-disable */
import darkModeMixin from '@/components/mixins/dark-mode-mixins';
import { dashboardUrl } from '@/assets/scripts/variables';
import { isDesktopApp, getDownloadAppLink } from '@/assets/scripts/utilities';
/* eslint-enable */

export default {
  name: 'UserMenuNavigation',
  components: {
    UserMenuProfilePicture,
    UserMenuNameLevel,
    UserMenuTheme,
  },
  mixins: [darkModeMixin],
  data() {
    return {
      downloadAppLink: getDownloadAppLink(),
    };
  },
  computed: {
    ...mapState('userData', ['showUserMenu']),
    ...mapState('team', ['teamSlug']),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('team', ['isInTeamWorkspace']),
    teamOrPersonalBaseLink() {
      return `${dashboardUrl()}${this.isInTeamWorkspace ? `/team/${this.teamSlug}` : ''}`;
    },
    myProjectsLink() {
      return `${this.teamOrPersonalBaseLink}/projects`;
    },
    brandsLink() {
      return `${this.teamOrPersonalBaseLink}/team-brands`;
    },
    settingsLink() {
      return `${this.teamOrPersonalBaseLink}/${this.isInTeamWorkspace ? 'team-settings/settings' : 'account-settings'}`;
    },
    navigation() {
      const navItems = [
        {
          name: 'My Projects',
          href: this.myProjectsLink,
        },
        {
          name: `${this.isInTeamWorkspace ? 'Team' : 'My'} Settings`,
          href: this.settingsLink,
        },
        {
          name: 'Learn OFFEO',
          href: 'https://offeo.com/tutorials',
          newTab: true,
        },
        {
          name: 'Shortcuts Keys',
          isFunction: true,
          method: this.openShortcutKey,
        },
        {
          name: 'Join Community',
          href: 'https://www.facebook.com/groups/offeo/',
          newTab: true,
        },
      ];

      if (this.isInTeamWorkspace) {
        navItems.splice(1, 0, {
          name: 'Team Projects',
          href: `${dashboardUrl()}/team/${this.teamSlug}/projects/team`,
        });
      }

      if (!this.isLiteMode) {
        navItems.splice(this.isInTeamWorkspace ? 2 : 1, 0, {
          name: `${this.isInTeamWorkspace ? 'Team' : 'My'} Brands`,
          href: this.brandsLink,
        });
      }

      return navItems;
    },
    showDownloadApps() {
      return !isDesktopApp() && !!JSON.parse(process.env.VUE_APP_SHOW_DESKTOP_APP_DOWNLOAD_BUTTON);
    },
  },
  methods: {
    ...mapMutations(['setShowSidebar']),
    ...mapMutations('assetsSidebar', [
      'setActiveGroup',
      'clearActiveKeyword',
      'clearActiveCategory',
    ]),
    ...mapMutations('userData', ['setShowUserMenu']),
    getHref(nav) {
      let target = nav.href;
      if (this.isLiteMode) target += '?mode=lite';
      return target;
    },
    openShortcutKey() {
      this.clearActiveKeyword();
      this.clearActiveCategory();

      this.setShowSidebar(true);
      this.setShowUserMenu(false);
      this.setActiveGroup('shortcut');
    },
    appIcon(image) {
      // eslint-disable-next-line
      return require(`@/assets/images/app-icons/${image}`);
    },
  },
};
</script>

<style lang="scss">
.user-menu__navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 101;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;

  &.is-show {
    opacity: 1;
    pointer-events: initial;
  }
}

.user-menu__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.user-menu__content {
  position: absolute;
  z-index: 2;
  left: 80px;
  bottom: 0;
  width: 300px;
  text-align: left;
  background: var(--lightgrey100-darkgrey700);
  height: auto;
  border-radius: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  transform: translateY(100%);
  transition: transform 0.15s ease-in-out;

  .user-menu__navigation.is-show & {
    transform: translateY(0);
  }
}

.user-menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 20px 15px;
  border-bottom: 1px solid $defaultBorder;

  &.is-dark {
    border-bottom: 1px solid rgba($defaultBorder, 0.1);
  }

  .user-profile__wrapper {
    display: flex;
    cursor: default;
    width: calc(100% - 60px); // 60px is the width of dark mode toggler buttons
  }

  .user-menu__profile-picture {
    margin-right: 15px;
  }
}

.user-menu__main {
  margin: 10px 0;

  .nav-list {
    &:nth-child(3) {
      padding-bottom: 10px;
      border-bottom: 1px solid $defaultBorder;

      &.is-dark {
        border-bottom: 1px solid rgba($defaultBorder, 0.1);
      }
    }

    &:nth-child(4) {
      padding-top: 10px;
    }


    &.download-app {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid $defaultBorder;

      &.is-dark {
        border-top: 1px solid rgba($defaultBorder, 0.1);
      }

      .icons {
        position: absolute;
        right: 0;
        top: 50%;

        .windows-icon {
          margin-right: 5px;
        }
      }

      .user-menu__button {
        .btn__content {
          > span {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .beta {
        font-size: 12px;
        padding: 5px 10px;
        background: $blue700;
        width: 50px;
        border-radius: 5px;
        margin-bottom: 10px;
        color: #fff;
      }

      .only-available-for-pc-and-mac {
        font-size: 12px;
        color: $lightGrey500;
        margin-top: 10px;
      }
    }
  }

  .btn {
    width: 100%;
    display: block;
    text-align: left;
  }

  .user-menu__button {
    text-decoration: none;
    font-size: 0.9375rem;
    font-weight: 400;
    position: relative;
    padding: 9px 38px;

    &:hover,
    &:focus {
      background: $lightGrey400;
    }

    &.is-dark {
      color: $light;
      background: transparent;

      &:hover,
      &:focus {
        background: rgba($darkGrey500, 0.5);
      }
    }
  }
}
</style>
